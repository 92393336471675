<template>
  <div class="data-table" ref="dataTable">
    <a-row class="action-bar" ref="actionBar" type="flex">
      <a-col class="action-bar-left" flex="1 0 auto"></a-col>
      <a-col class="action-bar-right" flex="1 0 auto">
        <a-button type="primary" :loading="exportLoading" @click="handleExport"> 导出 </a-button>
      </a-col>
    </a-row>

    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :row-key="(record, index) => record.awemeId"
      :pagination="false"
      :scroll="scroll"
    >
      <div class="aweme-info" slot="awemeInfo" slot-scope="text, record">
        <div class="aweme-info__cover">
          <BaseCover :src="record.awemeCover" :width="104" :height="138" />
        </div>
        <div class="aweme-info__content">
          <div>{{ record.awemeTitle || '-' }}</div>
          <router-link
            :to="{
              name: 'authorAnalysis',
              params: { authorId: record.authorId },
              query: { platform: record.platformType },
            }"
            target="_blank"
          >
            播主昵称：{{ record.nickname || '-' }}
          </router-link>
          <div>平台：{{ record.platform === 'douyin' ? '抖音' : '快手' }}</div>
          <div>媒体号：{{ record.code}}</div>
          <div>发布时间：{{ record.publishTime || '-' }}</div>
        </div>
      </div>
      <div slot="moduleInfo" slot-scope="text, record">
        <template v-if="record.moduleId"> {{ record.moduleName }}({{ record.moduleId }}) </template>
        <template v-else> 否 </template>
      </div>
      <div slot="dealerInfo" slot-scope="text, record">
        <div class="detail-item">
          <span>名称：</span>
          <span>{{ record.dealerName || '-' }}</span>
        </div>
        <div class="detail-item">
          <span>编码：</span>
          <span>{{ record.dealerStoreCode || '-' }}</span>
        </div>
        <div class="detail-item">
          <span>大区：</span>
          <span>{{ record.dealerAreaNames || '-' }}</span>
        </div>
        <div class="detail-item">
          <span>城市：</span>
          <span>{{ record.dealerCityName || '-' }}</span>
        </div>
        <div>
          <span>主体：</span>
          <span>{{ record.principalNames || '-' }}</span>
        </div>
      </div>
      <div slot="playInfo" slot-scope="text, record">
        <div class="detail-item">
          <span>播放量：</span>
          <a-tooltip>
            <template slot="title" v-if="record.playVolume >= 10000">
              {{ record.playVolume }}
            </template>
            {{ record.playVolume | humanReadable }}
          </a-tooltip>
        </div>
        <div class="detail-item">
          <span>完播率：</span>
          <span v-if="record.playTotalCountCompletionRate !== null">
            {{ (record.playTotalCountCompletionRate * 100) | humanReadable }}%
          </span>
          <span v-else>-</span>
        </div>
        <div class="detail-item">
          <span>均播时长：</span>
          <a-tooltip>
            <template slot="title" v-if="record.playTotalCountAvgTime >= 10000">
              {{ record.playTotalCountAvgTime }}
            </template>
            <span v-if="record.playTotalCountAvgTime !== null">
              {{ record.playTotalCountAvgTime | humanReadable }}s
            </span>
            <span v-else>-</span>
          </a-tooltip>
        </div>
      </div>
      <div slot="interactInfo" slot-scope="text, record">
        <div class="detail-item">
          <span>点赞量：</span>
          <a-tooltip>
            <template slot="title" v-if="record.diggCount >= 10000">
              {{ record.diggCount }}
            </template>
            {{ record.diggCount | humanReadable }}
          </a-tooltip>
        </div>
        <div class="detail-item">
          <span>评论量：</span>
          <a-tooltip>
            <template slot="title" v-if="record.commentCount >= 10000">
              {{ record.commentCount }}
            </template>
            {{ record.commentCount | humanReadable }}
          </a-tooltip>
        </div>
        <div class="detail-item">
          <span>转发量：</span>
          <a-tooltip>
            <template slot="title" v-if="record.shareCount >= 10000">
              {{ record.shareCount }}
            </template>
            {{ record.shareCount | humanReadable }}
          </a-tooltip>
        </div>
        <div class="detail-item">
          <span>增粉量：</span>
          <a-tooltip>
            <template slot="title" v-if="record.fansTotalAdd >= 10000">
              {{ record.fansTotalAdd }}
            </template>
            {{ record.fansTotalAdd | humanReadable }}
          </a-tooltip>
        </div>
      </div>
      <div slot="awemeShareShortLinkUrl" slot-scope="text, record">
        <a v-if="record.awemeShareShortLinkUrl" :href="record.awemeShareShortLinkUrl" target="_blank">{{
          record.awemeShareShortLinkUrl
        }}</a>
        <a v-else-if="record.awemeId != spinningId" @click="getShareUrl(record)">点击获取</a>
        <a-spin
          style="margin-left: 20px"
          v-show="record.awemeId == spinningId"
          tip="获取中"
          :spinning="spinning"
        ></a-spin>
      </div>
      <div slot="operate" slot-scope="text, record">
        <a @click="handleAweme(record)">播放视频</a>
        <br>
        <a @click="handleOperate('collectAweme', record)">收藏视频</a>
        <br>
        <router-link
          :to="{
            name: record.platform === 'douyin' ? 'awemeAnalysis' : 'awemeAnalysisKS',
            params: { awemeId: record.awemeId },
          }"
          target="_blank"
        >
          视频分析
        </router-link>
        <!-- <a @click="handleOperate('awemeAnalysis', record)">视频分析</a> -->
      </div>
    </a-table>

    <BasePagination
      ref="basePagination"
      :currentPage="pagination.page"
      :pageSize="pagination.size"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
import BaseCover from '@/components/BaseCover';
import resizeMixin from '@/mixins/dataTableMixin';
import awemeApi from '@/api/aweme';

const columns = [
  {
    title: '视频内容',
    key: 'awemeInfo',
    width: 400,
    scopedSlots: { customRender: 'awemeInfo' },
  },
  {
    title: '使用模板',
    key: 'moduleInfo',
    width: 150,
    scopedSlots: { customRender: 'moduleInfo' },
  },
  {
    title: '经销商信息',
    key: 'dealerInfo',
    width: 200,
    scopedSlots: { customRender: 'dealerInfo' },
  },
  {
    title: '播放统计',
    key: 'playInfo',
    width: 150,
    scopedSlots: { customRender: 'playInfo' },
  },
  {
    title: '互动统计',
    key: 'interactInfo',
    width: 150,
    scopedSlots: { customRender: 'interactInfo' },
  },
  {
    title: '分享短链接',
    key: 'awemeShareShortLinkUrl',
    width: 200,
    scopedSlots: { customRender: 'awemeShareShortLinkUrl' },
  },
  {
    title: '操作',
    key: 'operate',
    width: 165,
    fixed: 'right',
    scopedSlots: { customRender: 'operate' },
  },
];

export default {
  name: 'DataTable',
  components: {
    BaseCover,
  },
  mixins: [resizeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    pagination: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 10,
          total: 0,
        };
      },
    },
    exportLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      spinningId: '',
      spinning: false,
      columns,
      scroll: {},
    };
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.setTableScroll();
      });
    },
  },
  methods: {
    handleAweme(record){
      if(record.awemeId) {
        let url;
        if(record.platformType == 1)
          url = `https://www.iesdouyin.com/share/video/${ record.awemeId }/?region=CN&mid=6796480868782230792&u_code=354ddbk0h2ih&titleType=title&timestamp=1581666967&utm_campaign=client_share&app=aweme&utm_medium=ios&tt_from=copy&utm_source=copy`;
        else
          url = record.awemeUrl;
        window.open(url);
      }
    },
    // 获取分享短链接
    async getShareUrl(record) {
      this.spinningId = record.awemeId;
      this.spinning = true;
      let params = {
        awemeId: record.awemeId,
        platform: record.platformType,
      };
      const res = await awemeApi.fetchShareUrl(params);
      if (res.code == 200) {
        this.spinning = false;
        this.$message.success('获取成功');
        console.log(this.data.indexOf(record), '99999');
        this.data[this.data.indexOf(record)].awemeShareShortLinkUrl = res.data.link;
      } else {
        this.spinning = false;
        this.spinningId = ''
        this.$message.error('获取失败，请15分钟后再试。如重试3次仍未成功，请联系产品');
      }
    },
    handlePaginationChange(page, size) {
      this.$emit('pagination-change', page, size);
    },
    handleOperate(type, record) {
      this.$emit('operate', type, record);
    },
    handleExport() {
      this.$emit('export');
    },
    setTableScroll() {
      const winHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
      const width = this.$refs.dataTable.offsetWidth;
      const height = this.$refs.dataTable.offsetHeight;
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0);
      const scroll = {};

      if (width - 48 < columnsWidth) {
        scroll.x = columnsWidth;
      }

      if (height + 142 > winHeight) {
        const actionBarHeight = this.$refs.actionBar ? this.$refs.actionBar.$el.offsetHeight : 0;
        const tableHeadHeight = document.querySelector('.ant-table-thead').offsetHeight;
        const otherHeight = actionBarHeight + tableHeadHeight + 190;

        scroll.y = `calc(100vh - ${otherHeight}px)`;
      }

      this.scroll = scroll;
    },
  },
  mounted() {
    this.setTableScroll();
    this.$_resizeHandler = () => {
      this.setTableScroll();
    };
  },
};
</script>

<style lang="scss" scoped>
.data-table {
  padding: 24px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  .action-bar {
    padding: 0 0 16px;

    .action-bar-right {
      text-align: right;
    }
  }

  .aweme-info {
    display: flex;
    align-items: flex-start;

    &__cover {
      margin: 0 8px 0 0;
    }

    &__content {
      flex: 1;
    }
  }
}
</style>
