<template>
  <section class="page">
    <SearchFilter
      class="mb-16"
      :form="searchForm"
      :fetch-principal="fetchPrincipal"
      :principal-list="principalList"
      :fetch-region="fetchRegion"
      :region-list="regionList"
      :fetch-group="fetchGroup"
      :group-list="groupList"
      :search-loading="loading"
      @search="handleSearch"
    />

    <DataTable
      :loading="loading"
      :data="awemeList"
      :pagination="pagination"
      :export-loading="exportLoading"
      @pagination-change="handlePaginationChange"
      @operate="handleOperate"
      @export="handleExport"
    />

    <CollectAwemeModal
      ref="collectAwemeModal"
      v-model="collectAwemeVisible"
      :form="collectAwemeForm"
      :group-list="collectAwemeGroupList"
      :confirm-loading="collectAwemeLoading"
      @add-group="handleAddAwemeGroup"
      @confirm="handleCollectAwemeConfirm"
    />

    <AddAwemeGroupModal
      ref="addAwemeGroupModal"
      v-model="addAwemeGroupVisible"
      :form="addAwemeGroupForm"
      :confirm-loading="addAwemeGroupLoading"
      @confirm="handleAddAwemeGroupConfirm"
    />
  </section>
</template>

<script>
import SearchFilter from './components/SearchFilter';
import DataTable from './components/DataTable';
import CollectAwemeModal from '@/components/CollectAwemeModal';
import AddAwemeGroupModal from '@/components/AddAwemeGroupModal';
import baseApi from '@/api/base';
import dealerApi from '@/api/dealer';
import authorApi from '@/api/author';
import awemeApi from '@/api/aweme';

export default {
  name: 'AwemeList',
  components: {
    SearchFilter,
    DataTable,
    CollectAwemeModal,
    AddAwemeGroupModal,
  },
  data() {
    return {
      searchForm: {
        code: '',
        name: '',
        principalId: '',
        dealerAreaId: '',
        groupCate: '',
        groupId: undefined,
        platform: '',
        awemeTitle: '',
        useModule: '',
        timeType: '',
        time: [
          30,
          [
            this.$moment().subtract(30, 'days').format('YYYY-MM-DD'),
            this.$moment().subtract(1, 'days').format('YYYY-MM-DD'),
          ],
        ],
      },
      principalList: [{ label: '全部', value: '' }],
      regionList: [{ label: '全部', value: '' }],
      groupList: [],
      loading: false,
      awemeList: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      collectAwemeVisible: false,
      collectAwemeForm: {
        awemeId: '',
        groupIds: [],
      },
      collectAwemeGroupList: [],
      collectAwemeLoading: false,
      addAwemeGroupVisible: false,
      addAwemeGroupForm: {
        name: '',
      },
      addAwemeGroupLoading: false,
      exportLoading: false,
    };
  },
  mounted() {
    const { platform, code, useModule } = this.$route.query;

    platform && (this.searchForm.platform = Number(platform));
    code && (this.searchForm.code = code);
    useModule && (this.searchForm.useModule = Number(useModule));

    if (platform && code) {
      this.searchForm.timeType = '';
      this.searchForm.time = [];
    }
    console.log(this.$route.query);
    const {projectUpdateTime, traineeName} = this.$route.query;
    if(traineeName || projectUpdateTime) {
      this.searchForm.timeType = "";
      this.searchForm.time = [
        JSON.parse(projectUpdateTime).statisticsStartTime,
        JSON.parse(projectUpdateTime).statisticsEndTime,
      ];
      this.searchForm.name = traineeName;
    }

    this.fetchAwemeList();
  },
  methods: {
    // 获取主体列表
    async fetchPrincipal() {
      try {
        const { code, data, message } = await baseApi.fetchPrincipalList();

        if (code === 200) {
          this.principalList = [
            { label: '全部', value: '' },
            ...data.map((item) => {
              return { label: item.principal_name, value: item.id };
            }),
          ];
        } else {
          this.$message.error(message);
          this.principalList = [];
        }
      } catch (e) {
        console.error(e);
        this.principalList = [];
      }
    },
    // 获取大区列表
    async fetchRegion(principalId) {
      if (!principalId) {
        this.regionList = [{ label: '全部', value: '' }];
        return false;
      }

      try {
        const params = { principalId };
        const { code, data, message } = await baseApi.fetchRegionList(params);

        if (code === 200) {
          console.log(data);
          this.regionList = [
            { label: '全部', value: '' },
            ...data.map((item) => {
              return { label: item.region_names, value: item.region_id };
            }),
          ];
        } else {
          this.$message.error(message)
          this.regionList = []
        }
      } catch (e) {
        console.error(e);
        this.regionList = [];
      }
    },
    fetchGroup(groupCate, searchText) {
      if (!groupCate) {
        this.groupList = [];
        return false;
      }

      if (groupCate && !searchText) {
        this.groupList = [{ label: '全部', value: '' }];
        return false;
      }

      groupCate === 1 && this.fetchDealerPublicGroupList(searchText)
      groupCate === 2 && this.fetchAuthorPublicGroupList(searchText)
      groupCate === 3 && this.fetchMyDealerGroupList(searchText)
      groupCate === 4 && this.fetchMyAuthorGroupList(searchText)
    },
    async fetchDealerPublicGroupList(searchText) {
      try {
        const params = {
          name: searchText,
          pageNo: 1,
          limit: 50,
        };
        const { code, data, msg, errmsg } = await dealerApi.fetchDealerPublicGroupList(params);

        if (code === 0) {
          this.groupList = data.data.map((item) => {
            return { label: item.groupName, value: item.groupId };
          });
        } else {
          this.$message.error(msg || errmsg)
          this.groupList = []
        }
      } catch (e) {
        console.error(e);
        this.groupList = [];
      }
    },
    async fetchAuthorPublicGroupList(searchText) {
      try {
        const params = {
          name: searchText,
          page: 1,
          size: 50,
        };
        const { code, data, message } = await authorApi.fetchAuthorPublicGroupList(params);

        if (code === 200) {
          this.groupList = data.list.map((item) => {
            return { label: item.name, value: item.id };
          });
        } else {
          this.$message.error(message)
          this.groupList = []
        }
      } catch (e) {
        console.error(e);
        this.groupList = [];
      }
    },
    async fetchMyDealerGroupList(searchText) {
      try {
        const params = {
          groupName: searchText,
        };
        const { code, data, message } = await dealerApi.fetchMyDealerGroupList(params);

        if (code === 200) {
          this.rawGroupList = data;
          this.groupList = data.map((item) => {
            return { label: item.groupName, value: item.groupId };
          });
        } else {
          this.$message.error(message)
          this.groupList = []
        }
      } catch (e) {
        console.error(e);
        this.groupList = [];
      }
    },
    async fetchMyAuthorGroupList(searchText) {
      try {
        const params = {
          groupName: searchText,
        };
        const { code, data, message } = await authorApi.fetchMyAuthorGroupList(params);

        if (code === 200) {
          this.rawGroupList = data;
          this.groupList = data.map((item) => {
            return { label: item.groupName, value: item.groupId };
          });
        } else {
          this.$message.error(message)
          this.groupList = []
        }
      } catch (e) {
        console.error(e);
        this.groupList = [];
      }
    },
    handleSearch() {
      this.pagination.page = 1
      this.fetchAwemeList()
    },
    handlePaginationChange(page, size) {
      this.pagination.page = page
      this.pagination.size = size
      this.fetchAwemeList()
    },
    // 获取短视频列表
    async fetchAwemeList() {
      try {
        const { groupCate, groupId, time, ...restSearchForm } = this.searchForm;
        const { page, size } = this.pagination;
        if (time.length < 1) {
          this.$message.error('请先指定发布时间范围！');
          return;
        }

        this.loading = true;
        const params = {
          ...restSearchForm,
          startTime: time[1].length == 0 ? '' : `${time[1][0]} 00:00:00`,
          endTime: time[1].length == 0 ? '' : `${time[1][1]} 23:59:59`,
          page,
          size,
        };

        if (groupCate) {
          groupCate === 1 && (params.groupType = 1);
          groupCate === 2 && (params.groupType = 2);
          groupCate === 3 && (params.groupType = groupId ? (this.getIsCommon(groupId) === 1 ? 1 : 3) : '');
          groupCate === 4 && (params.groupType = groupId ? (this.getIsCommon(groupId) === 1 ? 2 : 4) : '');
          params.groupId = groupId || '';
        }

        const { code, data, message } = await awemeApi.fetchAwemeList(params);

        this.loading = false

        if (code === 200) {
          this.awemeList = data.list;
          this.pagination.total = data.total;
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
    },
    // 处理导出
    async handleExport() {
      this.exportLoading = true
      try {
        const { groupCate, groupId, time, ...restSearchForm } = this.searchForm;
        const params = {
          ...restSearchForm,
          startTime: time[1].length == 0 ? '' : `${time[1][0]} 00:00:00`,
          endTime: time[1].length == 0 ? '' : `${time[1][1]} 23:59:59`,
        };

        if (groupId) {
          groupCate === 1 && (params.groupType = 1);
          groupCate === 2 && (params.groupType = 2);
          groupCate === 3 && (params.groupType = this.getIsCommon(groupId) === 1 ? 1 : 3);
          groupCate === 4 && (params.groupType = this.getIsCommon(groupId) === 1 ? 2 : 4);
          params.groupId = groupId;
        }

        await awemeApi.exportAwemeList(params);
        this.exportLoading = false;
      } catch (e) {
        this.exportLoading = false;
        console.error(e);
      }
    },
    getIsCommon(groupId) {
      const index = this.rawGroupList.findIndex((item) => item.groupId === groupId);

      return index > -1 && this.rawGroupList[index].isCommon
    },
    handleOperate(type, record) {
      type === 'collectAweme' && this.openCollectAwemeModal(record.awemeId);
    },
    openCollectAwemeModal(awemeId) {
      this.collectAwemeVisible = true
      this.collectAwemeForm.awemeId = awemeId
      this.fetchMyAwemeGroupList()
    },
    // 获取我的短视频分组列表
    async fetchMyAwemeGroupList() {
      try {
        const { code, data, message } = await awemeApi.fetchMyAwemeGroupList();

        if (code === 200) {
          this.collectAwemeGroupList = data.map((item) => {
            return { groupId: item.id, groupName: item.name };
          });
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e);
      }
    },
    handleCollectAwemeConfirm() {
      this.collectAweme()
    },
    // 收藏短视频
    async collectAweme() {
      this.collectAwemeLoading = true
      try {
        const { awemeId, groupIds } = this.collectAwemeForm;
        const params = {
          awemeId,
          groupId: groupIds,
        };
        const { code, message } = await awemeApi.collectAweme(params);

        this.collectAwemeLoading = false

        if (code === 200) {
          this.$message.success('收藏视频成功');
          this.collectAwemeVisible = false;
          this.$refs.collectAwemeModal.resetForm();
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e);
        this.collectAwemeLoading = false;
      }
    },
    handleAddAwemeGroup() {
      this.addAwemeGroupVisible = true
    },
    handleAddAwemeGroupConfirm() {
      this.addAwemeGroup()
    },
    // 新建短视频分组
    async addAwemeGroup() {
      this.addAwemeGroupLoading = true
      try {
        const params = {
          ...this.addAwemeGroupForm,
        };
        const { code, message } = await awemeApi.addAwemeGroup(params);

        this.addAwemeGroupLoading = false

        if (code === 200) {
          this.$message.success('新建分组成功');
          this.addAwemeGroupVisible = false;
          this.$refs.addAwemeGroupModal.resetForm();
          this.fetchMyAwemeGroupList();
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e);
        this.addAwemeGroupLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 0 !important;
  background: #f0f2f5 !important;

  .mb-16 {
    margin-bottom: 16px;
  }
}
</style>
