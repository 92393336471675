<template>
  <div class="search-filter">
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :label-col="{ flex: '0 0 120px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 120px)'}"
    >
      <a-row :gutter="16">
        <a-col :span="colSpan1">
          <a-form-model-item label="媒体号" prop="code">
            <a-input
              v-model.trim="form.code"
              allow-clear
              placeholder="请输入媒体号"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item class="form-item" label="播主昵称/经销商名称/公司名称" prop="name">
            <a-input
              v-model.trim="form.name"
              allow-clear
              placeholder="请输入播主昵称/经销商名称/公司名称"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="主体(品牌)" prop="principalId">
            <a-select
              v-model="form.principalId"
              :default-active-first-option="false"
              option-filter-prop="children"
              show-search
              style="width: 100%;"
              @change="handlePrincipalChange"
            >
              <a-select-option
                v-for="item in principalList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="大区" prop="dealerAreaId">
            <a-select
              v-model="form.dealerAreaId"
              :disabled="form.principalId === ''"
              :default-active-first-option="false"
              option-filter-prop="children"
              show-search
              style="width: 100%;"
            >
              <a-select-option
                v-for="item in regionList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="平台" prop="platform">
            <a-select
              v-model="form.platform"
              :default-active-first-option="false"
              style="width: 100%;"
            >
              <a-select-option
                v-for="item in platformList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="视频标题" prop="awemeTitle">
            <a-input
              v-model.trim="form.awemeTitle"
              allow-clear
              placeholder="请输入视频标题"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="使用模板" prop="useModule">
            <a-select
              v-model="form.useModule"
              :default-active-first-option="false"
              style="width: 100%;"
            >
              <a-select-option
                v-for="item in useModuleList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan2">
          <a-form-model-item label="按分类选择分组" prop="groupId">
            <div class="group-wrap">
              <a-button-group>
                <a-button
                  v-for="item in groupCateList"
                  :key="item.value"
                  :type="item.value === form.groupCate ? 'primary' : 'default'"
                  style="padding: 0 8px;"
                  @click="handleGroupCateChange(item.value)"
                >
                  {{ item.label }}
                </a-button>
              </a-button-group>
              <a-select
                v-model="form.groupId"
                :default-active-first-option="false"
                :filter-option="false"
                show-search
                placeholder="先选分类，再选分组"
                style="width: 100%;"
                @search="handleGroupSearch"
              >
                <a-select-option
                  v-for="item in groupList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan2">
          <a-form-model-item label="发布时间" prop="time">
            <!-- <div class="time-wrap">
               <a-button-group>
                <a-button
                  v-for="item in timeTypeList"
                  :key="item.value"
                  :type="item.value === form.timeType ? 'primary' : 'default'"
                  style="padding: 0 8px;"
                  @click="handleTimeTypeChange(item.value)"
                >
                  {{ item.label }}
                </a-button>
              </a-button-group>
              <a-range-picker
                v-model="form.time"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                :allow-clear="false"
                @change="handleTimeChange"
                @calendarChange="calendarChange"
                @openChange="openChange"
              />
            </div> -->
            <TypeDateRangePicker :terraceType="terraceType" v-model="form.time"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan3">
          <a-form-model-item :wrapper-col="{ flex: '1 0 auto' }">
            <div class="btn-wrap">
              <a-button class="mr-8" @click="handleReset">
                重置
              </a-button>
              <a-button type="primary" :loading="searchLoading" @click="handleSearch">
                查询
              </a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import resizeMixin from '@/mixins/dataTableMixin'
import TypeDateRangePicker from '@/components/TypeDateRangePicker';
const platformList = [
  { label: '全部', value: '' },
  { label: '抖音', value: 1 },
  { label: '快手', value: 2 }
]

const useModuleList = [
  { label: '全部', value: '' },
  { label: '是', value: 1 },
  { label: '否', value: 0 }
]

const groupCateList = [
  { label: '经销商公共分组', value: 1 },
  { label: '播主公共分组', value: 2 },
  { label: '我的经销商分组', value: 3 },
  { label: '我的播主分组', value: 4 }
]

// const timeTypeList = [
//   { label: '今天', value: 0 },
//   { label: '昨天', value: 1 },
//   { label: '近7天', value: 7 },
//   { label: '近15天', value: 15 },
//   { label: '近30天', value: 30 }
// ]

export default {
  name: 'DataTable',
  components: {
    TypeDateRangePicker,
  },
  mixins: [resizeMixin],
  props: {
    form: {
      type: Object,
      default() {
        return {
          code: '',
          name: '',
          principalId: '',
          dealerAreaId: '',
          groupCate: 1,
          groupId: '',
          platform: '',
          awemeTitle: '',
          useModule: '',
          timeType: '',
          time: [ 30,
          [
            this.$moment().subtract(30, 'days').format('YYYY-MM-DD'),
            this.$moment().subtract(1, 'days').format('YYYY-MM-DD'),
          ]
          ],
        }
      }
    },
    fetchPrincipal: {
      type: Function,
      default() {
        return () => {}
      }
    },
    principalList: {
      type: Array,
      default() {
        return []
      }
    },
    fetchRegion: {
      type: Function,
      default() {
        return () => {}
      }
    },
    regionList: {
      type: Array,
      default() {
        return []
      }
    },
    fetchGroup: {
      type: Function,
      default() {
        return () => {}
      }
    },
    groupList: {
      type: Array,
      default() {
        return []
      }
    },
    searchLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.$_resizeHandler = () => {
      this.setColSpan()
    };

    return {
      terraceType:'sortAweme',
      platformList,
      useModuleList,
      groupCateList,
      colSpan1: 12,
      colSpan2: 24,
      colSpan3: 24,
      selectStartDay: '',
    }
  },
  created() {
    this.fetchPrincipal()
    this.setColSpan()
  },
  methods: {
    handlePrincipalChange(val) {
      this.form.dealerAreaId = ''
      this.fetchRegion(val)
    },
    handleDealerGroupSearch(val) {
      this.fetchDealerGroup(val)
    },
    handleAuthorGroupSearch(val) {
      this.fetchAuthorGroup(val)
    },
    handleGroupCateChange(val) {
      if (val === this.form.groupCate) {
        this.form.groupCate = ''
        this.form.groupId = undefined
      } else {
        this.form.groupCate = val
        this.form.groupId = ''
      }
      
      this.fetchGroup(this.form.groupCate, '')
    },
    handleGroupSearch(searchText) {
      this.fetchGroup(this.form.groupCate, searchText)
    },
    handleTimeTypeChange(val) {
      if (val === this.form.timeType) {
        this.form.timeType = ''
        this.form.time = []
      } else {
        this.form.timeType = val

        if (this.form.timeType === 0) {
          this.form.time = [
            this.$moment().format('YYYY-MM-DD'),
            this.$moment().format('YYYY-MM-DD')
          ]
        } else {
          this.form.time = [
            this.$moment().subtract(this.form.timeType, 'days').format('YYYY-MM-DD'),
            this.$moment().subtract(1, 'days').format('YYYY-MM-DD')
          ]
        }
      }
    },
    handleReset() {
      this.$refs.form.resetFields()
      this.form.groupCate = ''
      this.form.groupId = undefined
      this.fetchGroup(this.form.groupCate, '')
      this.form.timeType = ''
      this.fetchRegion(this.form.principalId)
      this.$emit('search', {...this.form})
    },
    handleSearch() {
      this.$emit('search', { ...this.form })
    },
    setColSpan() {
      const width = document.documentElement.offsetWidth

      if (width < 1200) {
        this.colSpan1 = 12
        this.colSpan2 = 24
        this.colSpan3 = 24
      } else if (width >= 1200 && width < 1600) {
        this.colSpan1 = 8
        this.colSpan2 = 24
        this.colSpan3 = 24
      } else if (width >= 1600) {
        this.colSpan1 = 6
        this.colSpan2 = 12
        this.colSpan3 = 24
      }
    },
    disabledDate(current) {
      return current > this.$moment(this.selectStartDay).add(89, 'days') || current < this.$moment(this.selectStartDay).subtract(89, 'days')
    },
    handleTimeChange(val) {
      this.form.timeType = ''
    },
    calendarChange(dates, dateStrings) {
      console.log(dates, dateStrings)
      this.selectStartDay = dates[0]
    },
    openChange(status) {
      this.selectStartDay = ''
    },
  },
}
</script>

<style lang="less" scoped>
.search-filter {
  padding: 24px 24px 16px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  .ant-form-item {
    display: flex;
    margin-bottom: 8px;

    /deep/ .ant-form-item-label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      line-height: 20px;
      white-space: pre-wrap;
    }
  }

  .group-wrap {
    display: flex;
    align-items: center;
    height: 40px;

    .ant-btn-group {
      margin-right: 8px;
    }
    
    .ant-select {
      flex: 1;
    }
  }

  .time-wrap {
    display: flex;
    align-items: center;
    height: 40px;

    .ant-btn-group {
      margin-right: 8px;
    }

    .ant-calendar-picker {
      flex: 1;
    }
  }

  .btn-wrap {
    text-align: right;
  }

  .mr-8 {
    margin-right: 8px;
  }
}
</style>
